import React, {Component} from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import MultiImageSlider from "../components/MultiImageSlider";
import "./post.css"
import SEO from "../components/SEO";
import Banner from "../components/Banner";
import {Grid} from "@material-ui/core";

class PostTemplate extends Component {
    render() {
        const post = this.props.data.wordpressPost;

        return (
            <Layout id='post'>
                <SEO title={post.yoast_meta.yoast_wpseo_title}
                     description={post.yoast_meta.yoast_wpseo_metadesc}
                />
                {
                    post.acf.banner &&
                    <Banner
                        title={post.acf.banner.title}
                        description={post.acf.banner.description}
                        image={post.acf.banner.image}
                    />
                }

                <Grid container type="flex" justify="center" className='content-wrapper'>
                    <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                        <h2 className='title-big'>{post.acf.banner.title}</h2>
                        <div className='post-content' dangerouslySetInnerHTML={{ __html: post.content }} />
                    </Grid>
                </Grid>
                {
                    this.props.data.wordpressPost.acf.blog_post.impressions && <MultiImageSlider nodes={this.props.data.wordpressPost.acf.blog_post.impressions}/>
                }
            </Layout>
        )
    }
}

PostTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                banner {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                blog_post {
                    impressions {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`